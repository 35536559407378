<template>
  <b-card
    :title="name"
    :img-alt="`${name}`"
    img-top
    tag="article"
    style="max-width: 40rem"
    class="mb-2 mt-5 fundraiser-card text-left"
  >
    {{ fundraiser.image }}
    <b-card-text class="text-left">
      {{ fundraiser.description }}
    </b-card-text>


    <div class="d-flex justify-content-left align-items-center">
      <b-button class="potomac-btn" variant="primary" :to="`/fundraiser/${fundraiser.id}`">
        Get Started
      </b-button>

      <div v-if="admin" class="ml-auto">
        <b-btn
          :class="{
            publish: !fundraiser.visible,
            unpublish: fundraiser.visible,
          }"
          @click="toggleVisible"
          v-if="!confirm"
        >
          <div v-if="fundraiser.visible">Unpublish</div>
          <div v-else>Publish</div>
        </b-btn>
      </div>
    </div>
  </b-card>
</template>

<script>
import admin from "@/components/shared/mixins/admin";
import {doc, updateDoc, getFirestore, deleteDoc} from "firebase/firestore";

export default {
  name: "Fundraiser",
  image: "Image",
  props: ["fundraiser"],
  mixins: [admin],
  data() {
    return {
      confirm: false,
      timer: 0,
    };
  },
  computed: {
    name() {
      if (this.admin && !this.fundraiser.visible) {
        return `(Draft) ${this.fundraiser.name}`;
      }
      return this.fundraiser.name;
    },
  },
  methods: {
    toggleVisible() {
      updateDoc(doc(getFirestore(), `fundraisers/${this.fundraiser.id}`), {
        visible: !this.fundraiser.visible,
      });
    },
    removeFundraiser() {
      deleteDoc(doc(getFirestore(), `fundraisers/${this.fundraiser.id}`));
    },

    incrementTimer() {
      this.timer = this.timer + 0.25;
      if (this.timer > 5.5) {
        this.confirm = false;
        this.timer = 0;
      } else {
        setTimeout(this.incrementTimer, 250);
      }
    },

    setConfirm() {
      this.confirm = true;
      setTimeout(this.incrementTimer, 250);
    },
  },
};
</script>

<style scoped>

.publish {
  background-color: green;
}

.unpublish {
  background-color: red;
}

.fundraiser-card {
  max-width: 600px;
  width: 80%;
}
</style>
