<template>
  <b-container class="admin-border mb-5">
    <b-alert v-model="added" dismissible>
      Fundraiser added
    </b-alert>
    <b-collapse
      id="new-fundraiser"
      title="New Fundraiser"
      class="left"
      v-model="visible"
    >
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group
          label="Fundraiser Name"
          label-for="fundraiser-name"
            description="What customers will see as the type of fundraiser."
        >
          <b-form-input
              id="fundraiser-name"
              placeholder="Fundraiser Name"
              v-model="form.name"
              required
          />
        </b-form-group>

        <b-form-file
            v-model="image"
            :state="Boolean(image)"
            placeholder="Upload Image here"
            drop-placeholder="Drop image here..."
            class="my-2"
        />
        <div v-if="uploadError">{{ uploadError }}</div>
        <b-btn @click="upload" size="sm" variant="success" class="my-3">Upload</b-btn>

        <b-form-group
            label="Fundraiser Description"
            label-for="fundraiser-description"
            description="A short description to explain the type of fundraiser to potential customers."
        >
          <b-form-textarea
              id="fundraiser-description"
              placeholder="Fundraiser Description"
              required
              rows="3"
              max-rows="10"
              v-model="form.description"
          />
        </b-form-group>

        <div>What information do you need from users?</div>
        <b-form-checkbox
            v-for="(value, key) in form.contact"
            :key="`contact-${key}`"
            :value="value"
            v-model="form.contact[key]"
        >
          {{ maskedNames[key] || key }}
        </b-form-checkbox>

        <div class="mt-4">
          What information do you need about the organization?
        </div>
        <b-form-checkbox
            v-for="(value, key) in form.orgInfo"
            :key="`org-${key}`"
            v-model="form.orgInfo[key]"
        >
          {{ maskedNames[key] || key }}
        </b-form-checkbox>

        <b-form-group
            class="mt-4"
            label="Fundraiser Terms"
            label-for="fundraiser-terms"
            description="The Terms and Conditions"
        >
          <b-form-textarea
              id="fundraiser-terms"
              placeholder="Fundraiser Terms & Conditions"
              required
              rows="5"
              max-rows="20"
              v-model="form.terms"
          />
        </b-form-group>

        <div class="mt-4">Any specific questions for the users?</div>
        <div class="my-2">
          <b-btn variant="success" size="sm" @click="addQuestion">Add Question</b-btn>
        </div>
        <div
            class="my-2 d-flex"
            v-for="(q, ind) in form.questions"
            :key="`question-${ind}`"
        >
          <div>
            <b-form-group
                :label="`Question ${ind + 1}`"
                :label-for="`question-${ind}`"
                description="Question will be asked when creating fundraising request"
            >
              <b-form-input
                  :id="`question-${ind}`"
                  placeholder="Question"
                  v-model="form.questions[ind].question"
                  required
              />
            </b-form-group>
            <b-form-checkbox v-model="form.questions[ind].required">
              Is it a required question?
            </b-form-checkbox>
          </div>
          <div class="ml-auto">
            <b-btn
                size="sm"
                @click="
                () => {
                  removeQuestion(ind);
                }
              "
            >X
            </b-btn
            >
          </div>
        </div>

        <div class="d-flex my-4">
          <b-btn class="ml-auto mr-3" size="sm" type="submit" variant="success">
            Create
          </b-btn>
          <b-btn size="sm" type="reset" class="mr-3"> Reset</b-btn>
          <b-btn size="sm" class="cancel" @click="visible = !visible">Cancel</b-btn>
        </div>
      </b-form>
    </b-collapse>
    <b-btn
        variant="success"
        class="m-3"
        size="sm"
        @click="visible = !visible"
        v-if="!visible"
    >
      Add New Fundraiser
    </b-btn>
  </b-container>
</template>

<script>
import {collection, addDoc, getFirestore, updateDoc, doc} from "firebase/firestore";
import {mapActions} from "vuex";
import {getStorage, ref, uploadBytes} from "firebase/storage";

export default {
  name: "NewFundraiser",
  components: {},
  data() {
    return {
      image: null,
      uploadError: false,
      editImage: false,
      visible: false,
      form: {
        name: "",
        description: "",
        terms: "",
        contact: {
          name: true,
          email: true,
          phoneNumber: true,
        },
        orgInfo: {
          type: true,
          orgName: true,
          address: true,
          w9Required: false,
        },
        questions: [],
      },
      added: false,
      maskedNames: {
        email: 'Email Address',
        name: 'Name of User',
        orgName: 'Name of Organization',
        phoneNumber: 'Phone Number',
        address: 'Address',
        w9Required: 'W9 Required',
        type: 'Type of Fundraiser',

      }
    };
  },

  methods: {
    ...mapActions('events', ['getEvents']),
    upload() {
      if (this.image) {
        if (this.userOwned) {
          const storage = getStorage();
          const imageRef = ref(storage, `image/${this.id}`);
          this.uploadError = null;
          uploadBytes(imageRef, this.image).then((snapshot) => {
            updateDoc(doc(getFirestore(), `fundraiser/${this.id}`), {
              image: snapshot.metadata.fullPath
            })
          }).catch(e => {
            this.uploadError = "File too large"
          })

        } else {
          this.uploadError = "Not Authorized"
        }
      } else {
        this.uploadError = "Please add a file"
      }
    },

    onSubmit(event) {
      event.preventDefault();
      addDoc(collection(getFirestore(), "fundraisers"), {
        ...this.form,
      }).then(() => {
        this.form = {
          name: "",
          description: "",
          contact: {
            name: true,
            email: true,
            phoneNumber: true,
          },
          orgInfo: {
            type: true,
            name: true,
            address: true,
            w9: true,
            w9Required: false,
          },
          questions: [],
        };
        this.added = true;
        this.visible = false;
      });
    },

    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.description = "";
      this.form.name = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    addQuestion() {
      this.form.questions.push({
        question: "",
        required: false,
      });
    },
    removeQuestion(ind) {
      this.form.questions.splice(ind, 1);
    },
  },

  mounted() {
    if (this.loading) {
      this.getEvents();
    }
  },
};
</script>

<style scoped>
.left {
  text-align: left;
}

.cancel {
  background-color: red;
}

</style>
