<template>
  <div class="d-flex flex-column">
    <PageTitle title="Fundraisers"/>
<!--    <NewFundraiser v-if="admin"/>-->
    <b-container class="d-flex justify-content-center flex-wrap">
      <Fundraiser
        v-for="(fundraiser, ind) in fundraisers"
        :key="`${fundraiser.name}-${ind}`"
        :fundraiser="fundraiser"
      />
    </b-container>
    <Divider large class="mt-auto"/>
  </div>
</template>

<script>
import PageTitle from "@/components/shared/PageTitle";
import admin from "@/components/shared/mixins/admin";
import NewFundraiser from "@/views/Admin/NewFundraiser";
import Fundraiser from "@/components/Fundraiser";
import Divider from "@/components/shared/Divider";
import {mapActions, mapState} from 'vuex';


export default {
  name: "Fundraisers",
  components: {Fundraiser, NewFundraiser, PageTitle, Divider},
  mixins: [admin],
  data() {
    return {
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('fundraisers', ['fundraisers'])
  },
  methods: {
    ...mapActions('fundraisers', ['getAllFundraisers'])
  },
  mounted() {
    if (this.admin){
      this.getAllFundraisers()
    }
  }
};
</script>

<style scoped></style>
